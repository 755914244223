<template>
  <CCard>
    <CCardHeader>
      <div class="d-flex justify-content-between">
        Usuarios
        <CButton class="btn btn-add" size="sm" @click="addUser()">
          Añadir usuario
        </CButton>
      </div>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :items="users"
        :fields="fields"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{
          label: 'Usuarios por página:',
          values: [5, 10, 25, 50, 100, 250, 500],
        }"
        :table-filter="{
          label: 'Filtro:',
          placeholder: 'Comience a escribir...',
        }"
        :no-items-view="{
          noResults: 'No se han encontrado datos',
          noItems:
            'No se han encontrado datos que cumplan las características indicadas',
        }"
        pagination
      >
        <template #username="{ item }">
          <td>
            <a class="text-info" @click="userInfo(item.acciones)">{{
              item.username
            }}</a>
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="success"
              class="btn btn-info"
              shape="pill"
              size="sm"
              style="margin-right: 3px"
              @click="userInfo(item.acciones)"
            >
              Información
            </CButton>
            <CButton
              color="success"
              class="btn btn-add"
              shape="pill"
              size="sm"
              style="margin-right: 3px"
              @click="assignPathologies(item.acciones)"
            >
              Asignar patologías
            </CButton>
            <CButton
              color="success"
              class="btn btn-add"
              shape="pill"
              size="sm"
              style="margin-right: 3px"
              @click="assignChallenges(item.acciones)"
            >
              Asignar retos
            </CButton>
            <CButton
              color="success"
              class="btn btn-add"
              shape="pill"
              size="sm"
              style="margin-right: 3px"
              @click="assignAlerts(item.acciones)"
            >
              Asignar alertas
            </CButton>
            <CButton
              color="success"
              class="btn btn-add"
              shape="pill"
              size="sm"
              style="margin-right: 3px"
              @click="assignLimits(item.acciones)"
            >
              Asignar límites
            </CButton>
            <CButton
              color="success"
              class="btn btn-info"
              shape="pill"
              size="sm"
              style="margin-right: 3px"
              @click="seeSessions(item.acciones)"
            >
              Ver Sesiones
            </CButton>
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="deleteButton(item)"
            >
              Eliminar
            </CButton>

            <CModal
              id="modalElim"
              title="Eliminar usuario"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el usuario: "{{ user.name }}
                {{ user.surname }}"? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deleteUser(user.acciones)"
                  style="margin-right: 1em"
                >
                  Aceptar
                </CButton>
                <CButton color="danger" @click="deleteModal = false">
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import {
  getUsersData,
  checkUser,
  changeDateFormat,
  getUserLimit,
} from "../utils/utils";
export default {
  name: "TablaUser",
  props: {
    numero: 5,
    fields: {
      type: Array,
      default() {
        return [
          { key: "name", label: "Nombre" },
          { key: "surname", label: "Apellidos" },
          {
            key: "email",
            label: "E-mail",
          },
          {
            key: "gender",
            label: "Género",
          },
          { key: "weight", label: "Peso" },
          { key: "height", label: "Altura" },
          { key: "minimum_limit", label: "Límite mínimo" },
          { key: "maximum_limit", label: "Límite máximo" },
          {
            key: "description",
            label: "Descripción",
          },
          {
            key: "birthdate",
            label: "Fecha de nacimiento",
          },
          {
            key: "acciones",
            sorter: false,
            filter: false,
          },
        ];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
  },
  data() {
    return {
      deleteModal: false,
      user: {},
      users: [],
      GENDER_MEN: "Hombre",
      GENDER_WOMAN: "Mujer",
      GENDER_OTHER: "Otros",
      minimumLimit: 0,
      maximunLimit: 0,
    };
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
     receiveData() {
       this.getUsers();
    },
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla.
     */
     getUsers() {
      let userData = getUsersData();
       userData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let data = [];
        let element = {};

        result.users.forEach((user) => {
          element = {};
          element.name = user.name;
          element.surname = user.surname;
          element.email = user.email;
          element.minimum_limit = user.min_limit
          element.maximum_limit = user.max_limit

          if (user.gender == 0) {
            element.gender = this.GENDER_MEN;
          } else if (user.gender == 1) {
            element.gender = this.GENDER_WOMAN;
          } else {
            element.gender = this.GENDER_OTHER;
          }
          element.weight = user.weight + " kg";
          if (user.height > 100) {
            element.height = user.height / 100 + " m";
          } else {
            element.height = user.height + " m";
          }
          element.description = user.description;

          let birthdate = changeDateFormat(user.birthdate);
          element.birthdate = birthdate;
          element.acciones = user.id_user;
          data.push(element);
        });

        this.users = data;
      });
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el nombre del usuario o en el botón de Información en la columna de acciones, nos llevará a la página para ver la usuario del premio
     *
     * @param {number} id - El ID del usuario a tomar su informacion
     */
    userInfo(id) {
      this.$router.push("/user/info/" + id);
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el botón de Asignar patologías en la columna de acciones, nos llevará a la página para asignar patologiás a ese usuario.
     *
     * @param {number} id - El ID del usuario a tomar su informacion
     */
    assignPathologies(id) {
      this.$router.push("/user/pathology/" + id);
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el botón de Asignar Retos en la columna de acciones, nos llevará a la página para asignar retos a ese usuario.
     *
     * @param {number} id - El ID del usuario a tomar su informacion
     */
    assignChallenges(id) {
      this.$router.push("/user/challenge/" + id);
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el botón de Asignar alertas en la columna de acciones, nos llevará a la página para asignar alertas a ese usuario.
     *
     * @param {number} id - El ID del usuario a tomar su informacion
     */
    assignAlerts(id) {
      this.$router.push("/user/alert/" + id);
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el botón de Asignar limites en la columna de acciones, nos llevará a la página para asignar limites a ese usuario.
     *
     * @param {number} id - El ID del usuario a tomar su informacion
     */
    assignLimits(id) {
      this.$router.push("/user/limit/" + id);
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el botón de ver sesioens en la columna de acciones, nos llevará a la página para ver las sesiones a ese usuario.
     *
     * @param {number} id - El ID del usuario a tomar su informacion
     */
    seeSessions(id) {
      this.id_userPasado = id;
      this.$router.push("/user/sessions/" + id);
    },
    /**
     * Este metodo se ejecuta al hacer click en el boton añadir usuario del header, nos llevará a la página para crear un nuevo usuario
     */
    addUser() {
      this.$router.push("/user/create/");
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal.
     *
     * @param {object} user - El usuario sobre el que se ha llamado al evento
     */
    deleteButton(user) {
      this.user = user;
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el usuario, mandando por la URL el id
     *
     * @param {number} id - El id del usuario a eliminar
     */
    deleteUser(id) {
      const token = localStorage.token;

      axios
        .delete(config.BACK_IP + "/user/" + id, { headers: { token: token } })
        .then(() => {
          this.getUsers();
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/pages/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
      this.deleteModal = false;
    },
  },
};
</script>