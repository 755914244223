<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper>
          <template #header>
              Usuarios
          </template>
        </CTableWrapper>       
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/UsersTable.vue";
import {checkUser} from '../../utils/utils';
import "core-js/stable";
import "regenerator-runtime/runtime";
export default {
  name: "UserTable",
  components: { CTableWrapper },
  created() {
    if(!checkUser()) return;
  },
};
</script>